<template>
  <div class="card card-no-hover w-100 masonry-grid mt-30">
    <div class="card-header">
      {{ title }}
    </div>
    <div class="card-body">
      <ul class="card-text list-unstyled">
        <li v-for="(item, _i) in list" :key="_i">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CriteriaCard',
  props: {
    title: {
      type: String,
      required: false
    },
    list: {
      type: Array,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
