<template>
  <div class="col-md-6 mb-35">
    <div class="card p-30 h-100">
      <div class="card-body">
        <h6 class="card-tagline">{{ title }}:<br> {{ subtitle }}</h6>
        <p class="card-text mt-3 text-pre-wrap" v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EachCountryCardDeck',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
