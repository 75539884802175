<template>
  <div>
    <CountryTabContentTopMessage
      :description="topMessage.description"
      :title="topMessage.title"
    />
    <div class="row mt-50">
      <div class="col-lg-12 m-auto">
        <div class="card-deck text-center">
          <AllCountryCardDeck
            v-for="(item, _i) in cardDecks"
            :key="_i"
            :description="item.description"
            :icon-path="item.icon ? item.icon.relative_url : ''"
          />
        </div>
      </div>
    </div>

    <h3 class="text-primary mt-70 mb-30 font-22">NKG Bloom Criteria</h3>
    <div class="row masonry-card-row">
      <div v-for="(criteria, _i) in bloomCriteria" :key="_i" class="col-lg-4 col-12 d-flex">
        <CriteriaCard
          :list="criteria.list" :title="criteria.title"/>
      </div>
    </div>
  </div>
</template>

<script>
import AllCountryCardDeck from '@/components/user/inclusive-sustainability/AllCountryCardDeck'
import CriteriaCard from '@/components/user/inclusive-sustainability/CriteriaCard'
import CountryTabContentTopMessage from '@/components/common/tab/CountryTabContentTopMessage'

export default {
  name: 'AllCountryTabContent',
  components: {
    CriteriaCard,
    AllCountryCardDeck,
    CountryTabContentTopMessage
  },
  computed: {
    topMessage () {
      return this.$store.getters.getSustainabilityAllCountryGreetings
    },
    cardDecks () {
      return this.$store.getters.getSustainabilityAllCountryCardDecks
    },
    bloomCriteria () {
      const criteriums = this.$store.getters.getSustainabilityAllCountryCriteriums
      return criteriums.map((item) => {
        return {
          title: item.title,
          list: item.description.split('\n')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
