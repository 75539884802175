<template>
  <div>
    <div v-for="(item, _index) in widgetDecks[year]" :key="_index"
         class="row mb-25 bg-white py-30 m-0">
      <div class="col-lg-4">
        <span class="text-f2 mr-10 font-18 font-weight-bold">
          Assessment period:
        </span>
        <p class="card-text text-pre-wrap mt-0">{{ item.title }}</p>
      </div>
      <div class="col-lg-5">
        <div class="row">
          <div class="col-lg-10 align-items-center">
          <span class="text-f2 mr-10 font-18 font-weight-bold">
          Identified priority areas:
        </span>
            <p class="card-text text-pre-wrap mt-0">{{ item.subtitle }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <span class="text-f2 mr-10 font-18 font-weight-bold">
          Sample size:
        </span>
        <p class="card-text text-pre-wrap mt-0">{{ item.description }}</p>
      </div>
    </div>
    <div v-show="widgetDecks[year].length === 0 && cardDecks[year].length === 0" class="text-center">No data available
    </div>
    <div class="row">
      <div class="col-lg-12 m-auto">
        <div class="row text-left">
          <EachCountryCardDeck
            v-for="(cardDeck, _cIndex) in cardDecks[year]"
            :key="_cIndex"
            :description="cardDeck.description"
            :subtitle="cardDeck.subtitle"
            :title="cardDeck.title"
          ></EachCountryCardDeck>
        </div>
      </div>
    </div>
    <div class="mt-70">
      <h4 class="text-primary mb-20">{{ resourceTitle.title }}</h4>
      <div class="row px-lg-0 px-md-0 px-30">
        <div v-for="(resource, _rIndex) in resources"
             :key="_rIndex"
             class="col-md-12 col-lg-7 mr-auto"
        >
          <Resource
            :date="resource.date"
            :description1="resource.subtitle"
            :description2="resource.description"
            :download-resource="resource"
            :icon-path="resource.icon ? resource.icon.relative_url : ''"
            :title="resource.title"
          ></Resource>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EachCountryCardDeck from '@/components/user/inclusive-sustainability/EachCountryCardDeck'
import Resource from '@/components/common/Resource'

export default {
  name: 'CountryYearTabContent',
  components: {
    Resource,
    EachCountryCardDeck
  },
  props: {
    resources: {
      type: Array,
      required: true
    },
    resourceTitle: {
      type: Object,
      required: true
    },
    cardDecks: {
      type: Object,
      required: true
    },
    widgetDecks: {
      type: Object,
      required: true
    },
    year: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>

</style>
