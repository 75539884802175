<template>
  <div class="card py-35 px-15 card-no-hover">
    <div class="card-body">
      <img v-if="!iconPath" :src="require(`@/assets/img/${iconName}`)" alt="">
      <img v-if="iconPath" :src="iconPath | makeAbsUrl" alt="" class="image-icon-lg">
      <p class="text-center card-text mt-3 text-pre-wrap" v-html="description"></p>
    </div>
  </div>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'AllCountryCardDeck',
  mixins: [UtilityMixin],
  props: {
    iconName: {
      type: String,
      required: false,
      default: 'nut.svg'
    },
    iconPath: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
