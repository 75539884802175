<template>
  <inclusive-sustainability></inclusive-sustainability>
</template>

<script>
import InclusiveSustainability from '@/components/user/inclusive-sustainability/InclusiveSustainability'

export default {
  name: 'InclusiveSustainabilityView',
  components: { InclusiveSustainability }
}
</script>

<style scoped>

</style>
