import { render, staticRenderFns } from "./CountryYearTabContent.vue?vue&type=template&id=8fdf3e06&scoped=true&"
import script from "./CountryYearTabContent.vue?vue&type=script&lang=js&"
export * from "./CountryYearTabContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fdf3e06",
  null
  
)

export default component.exports