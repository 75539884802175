<template>
  <div class="bg-white px-25 py-30 d-flex justify-content-between align-items-end">
    <div class="d-flex">
      <div class="icon mr-20">
        <img v-if="!iconPath" :src="require(`@/assets/img/icons/${iconName}`)" alt="Icon" class="image-icon"
             @click.prevent="getDownloadLink(downloadResource)">
        <img v-if="iconPath" :src="iconPath | makeAbsUrl" alt="Icon" class="image-icon"
             @click.prevent="getDownloadLink(downloadResource)">
      </div>
      <div class="text-content">
        <h6 class="card-tagline">{{ title }}</h6>
        <p class="card-desc">{{ description1 }}</p>
      </div>
    </div>
    <div>
      <span class="text-primary">{{ date | humanDate }}</span>
      <!--      <span class="text-primary ">{{ description2 | dataUnitValue }}</span>-->
    </div>
  </div>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'Resource',
  mixins: [UtilityMixin],
  props: {
    iconName: {
      type: String,
      required: false,
      default: 'story.png'
    },
    iconPath: {
      type: String,
      required: false
    },
    downloadResource: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    date: {
      type: [String, Number],
      required: true
    },
    description1: {
      type: String,
      required: false,
      default: ''
    },
    description2: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.icon:hover {
  cursor: pointer;
}
</style>
