<template>
  <div class="row align-items-start">
    <div class="col-sm-12 col-md-8 col-lg-9 highchart">
      <h3 class="text-primary font-30">{{ title }}</h3>
      <h5 v-show="subtitle !== ''" class="text-dark">{{ subtitle }}</h5>
      <p v-show="description !== ''" class="mt-20 font-18 text-pre-wrap" v-html="description"></p>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-3 highchart text-left text-lg-right text-md-right my-lg-0 my-md-0 my-30">
      <img v-if="!groupLogo" alt="Becamo" height="auto" src="@/assets/img/logo.svg" width="225px">
      <img v-if="groupLogo" :src="groupLogo | makeAbsUrl" alt="" height="auto" width="225px">
    </div>
  </div>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'EachCountryTabContentTopMessage',
  mixins: [UtilityMixin],
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    groupLogo: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
