<template>
  <div>
    <EachCountryTabContentTopMessage
      :description="topMessage.description"
      :group-logo="topMessage.icon ? topMessage.icon.relative_url : ''"
      :title="topMessage.title"
    />
    <h4 class="text-primary mt-30 mb-70">Social and environmental assessments</h4>
    <div class="tab-inside-tab mt-60 mb-60">
      <ul :id="country.public_code + 'yearTab'"
          class="nav nav-pills justify-content-center border-bottom" role="tablist">
        <li v-for="(item, _i) in tabbedYear" :key="_i" class="nav-item" role="presentation">
          <a
            :id="country.public_code + 'year-tab-' + _i + '-tab'"
            :aria-controls="'#' + country.public_code + 'year-tab-' + _i"
            :class="_i === 0 ? 'active':''"
            :href="'#' + country.public_code + 'year-tab-' + _i"
            class="nav-link font-18" data-toggle="pill">
            {{ item }}
          </a>
        </li>
      </ul>
      <div id="yearTabContent" class="tab-content mt-50 text-left">
        <div
          v-for="(item, _i) in tabbedYear" :id="country.public_code + 'year-tab-' + _i"
          :key="_i"
          :aria-labelledby="country.public_code + 'year-tab'"
          :class="{ 'show active': _i === 0 }" class="tab-pane fade">
          <country-year-tab-content
            :card-decks="cardDecksByYear"
            :resource-title="resourceTitle"
            :resources="allResources"
            :widget-decks="widgetDecksByYear"
            :year="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryYearTabContent from '@/components/user/inclusive-sustainability/CountryYearTabContent'
import EachCountryTabContentTopMessage from '@/components/common/tab/EachCountryTabContentTopMessage'
import _ from 'lodash'

export default {
  name: 'EachCountryTabContent',
  components: {
    CountryYearTabContent,
    EachCountryTabContentTopMessage
  },
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabbedYear: [
        ..._.range(2020, new Date().getFullYear() + 1, 1)
      ]
    }
  },
  computed: {
    topMessage () {
      return this.$store.getters.getSustainabilityCountryGreetings(this.country.name)
    },
    keyServiceTopMessage () {
      return this.$store.getters.getSustainabilityCountryKeyServiceGreetings(this.country.name)
    },
    widgetDecksByYear () {
      const _data = {}
      const _allYear = 'All Year'
      this.tabbedYear.map((item) => {
        if (item === _allYear) {
          _data[_allYear] = this.$store.getters.getSustainabilityCountryYearWidgetDecks(
            this.country.name, 'all')
        } else {
          _data[item] = this.$store.getters.getSustainabilityCountryYearWidgetDecks(
            this.country.name, JSON.stringify(item))
        }
      })
      return _data
    },
    resourceTitle () {
      return this.$store.getters.getSustainabilityCountryResourceTitle(this.country.name)
    },
    allResources () {
      return this.$store.getters.getSustainabilityCountryResources(this.country.name)
    },
    cardDecksByYear () {
      const _data = {}
      const _allYear = 'All Year'
      this.tabbedYear.map((item) => {
        if (item === _allYear) {
          _data[_allYear] = this.$store.getters.getSustainabilityCountryCardDecksByYear(
            this.country.name, 'all')
        } else {
          _data[item] = this.$store.getters.getSustainabilityCountryCardDecksByYear(
            this.country.name, JSON.stringify(item))
        }
      })
      return _data
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  a {
    border-radius: 0;
  }
}
</style>
