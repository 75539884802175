<template>
  <section class="mt-40">
    <bw-loader v-if="loading"/>
    <div class="container" v-if="!loading">
      <div class="row">
        <div class="col">
          <div>
            <CountryTab :countries="countries"/>
            <div class="tab-content mt-50">
              <div id="tab-1" class="tab-pane fade show"
                   :class="{ active: activeTab === 'ALL' }"
                   role="tabpanel">
                <all-country-tab-content/>
              </div>
              <div
                v-for="country in countries" :key="country.id"
                class="tab-pane fade show"
                :class="{ active: activeTab === country.public_code }"
                role="tabpanel" :id="'country-tab-' + country.id">
                <each-country-tab-content :country="country"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EachCountryTabContent from '@/components/user/inclusive-sustainability/EachCountryTabContent'
import AllCountryTabContent from '@/components/user/inclusive-sustainability/AllCountryTabContent'
import CountryTab from '@/components/common/tab/CountryTab'
import UtilityMixin from '@/mixins/utility-mixin'
import BwLoader from '@/components/ui/BwLoader'
import _ from 'lodash'

export default {
  name: 'InclusiveSustainability',
  mixins: [UtilityMixin],
  components: {
    BwLoader,
    EachCountryTabContent,
    AllCountryTabContent,
    CountryTab
  },
  data () {
    return {
      loading: false,
      activeTab: 'ALL'
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('getSustainabilityContentsFromServer')
    this.loading = false
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'ALL'
  },
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'ALL'
    }
  }
}
</script>

<style scoped>

</style>
